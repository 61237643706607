import React from "react"
import { Link } from "gatsby"

import PostMetadata from "./PostMetadata"

export default props => {
  const { posts } = props
  return (
    <div>
      {posts.map((post, index) => {
        console.log(post);
        return (
          <div key={index} className='flex items-center rounded py-4 shadow-md px-3 my-2'>
            <div className='md:h-48 md:w-64'>
              <img
                className='rounded-full md:rounded h-full w-full'
                src={post.featuredImage.url}
              />
            </div>
            <div className='ml-4 flex flex-col w-full'>
              <Link to={post.fields.slug} className='text-xl md:text-3xl font-medium text-gray-800'>
                {post.title}
              </Link>
              <div className='text-sm text-gray-700'>
                <PostMetadata post={post} compact />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
