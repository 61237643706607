import React from "react";
import { graphql } from "gatsby";
import ServiceLayout from "../components/ServiceLayout";
import ServiceTitle from "../components/ServiceTitle";
import BlogRoll from "../components/blog/BlogRoll";
import ArticleSelfAd from "../components/blog/ArticleSelfAd";
import { sortByDate } from "../services/util";
import "../styles.css";

export const BlogPage = ({ data }) => {
  const posts = sortByDate(data.allStrapiArticle.edges).filter(n => n.tags.includes('copywriting') || n.tags.includes('blog'));

  let titleComponent = (
    <ServiceTitle
      title='The Red Brick Blog'
      subtitle='Learn about western history, interesting and unique local businesses as well as some web development tips. Feel free to peruse our writing!'
    />
  );

  let content = (
    <div className='mx-4 flex flex-col items-center justify-center my-8 md:max-w-4xl'>
      <div className='mb-8'>
        <ArticleSelfAd compact />
      </div>
      <BlogRoll posts={posts} />
    </div>
  );
  return <ServiceLayout
    helmetTitle="Web development and Local Business Writing Blog | Copywriting, Search Engine Optimization, SEO | Red Brick Road Studio"
    titleComponent={titleComponent}>{content}</ServiceLayout>;
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogQuery {
    allStrapiArticle {
      edges {
        node {
          id
          title
          content
          tags
          fields {
            slug
          }
          featuredImage {
            url
          }
          updated_at
          created_at
          user {
            username
            avatar {
              url
            }
            twitter
            instagram
          }
        }
      }
    }
  }
`;
