export const sortByDate = posts => {
  const sortedPosts = posts
    .map(edge => edge.node)
    .sort((a, b) => {
      const dateA = new Date(a.updated_at)
      const dateB = new Date(b.updated_at)
      if (dateA < dateB) {
        return 1
      }
      if (dateA > dateB) {
        return -1
      }

      return 0
    })
  return sortedPosts
}
