import React from "react";

export default props => {
  const { title, subtitle, children } = props;
  return (
    <div className='mt-6 mb-24 mx-6 sm:mx-4 md:mt-12 rounded p-6 bg-primary text-gray-100'>
      <div className="pb-6">
        <h1 className='font-semibold text-2xl md:text-3xl lg:text-4xl mb-2'>{title}</h1>
        <p className='text-base'>{subtitle}</p>
      </div>
      {children}
    </div>
  );
};
